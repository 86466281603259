.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  /* .App-logo {
    animation: App-logo-spin infinite 20s linear;
  } */
}

.App-header {
  background-color: #5f657d;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.Socials {
  height: 50px;
}

@media only screen and (min-width: 480px) {
 .Social-link {
  width: 150px;
float:left;
color: #61dafb;
} 
}
@media only screen and (max-width: 480px) {
  .Social-link {
   width: 50px;
 clear:left;
 min-height: 20vmin;
 color: #61dafb;
 } 
 }



@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
